<template>
    <div class="w-full">
        <template v-if="notificationType.notification_type_id">
            <v-text>
                You have been unsubscribed from {{ notificationType.name }}. <br> You may change these settings in your
                <!-- eslint-disable-next-line -->
                <v-link class="inline" :to="{ name: 'me.notifications'}">user profile</v-link>.
            </v-text>
        </template>

        <template v-else>
            <v-loader />
        </template>
    </div>
</template>

<script>
import NotificationTypeService from "@/services/modules/notification-type-service";

export default {
    data() {
        return {
            notificationType: {
                notification_type_id: null,
            },
        };
    },
    created() {
        NotificationTypeService.show(this.$route.params.notificationType)
            .then((response) => {
                this.notificationType = response.data.data;
            });
    },
};
</script>
